/** IMPORTS **/

@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css";


/** WEB PAGE **/

*,
html,
input[type=checkbox],
input[type=radio] {
    box-sizing: border-box
}

.no-decoration {
    text-decoration: none
}

body,
html {
    height: 100%;
    margin: 0
}

body {
    overflow-x: hidden
}

body:after {
    background-image: none
}

#wrapper {
    overflow: hidden;
    position: relative;
    min-height: 100%
}

.desc-card .filter {
    background-color: rgba(0, 0, 0, .7)
}


/** FIX MALEXTRAP BUG **/

.btn {
    line-height: .5em !important
}